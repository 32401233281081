.App {
    display: flex;
    height: 100vh;
    flex-direction: column;
}

main {
    flex-grow: 1;
    position: relative;
    order: 1;
}

aside {
    background-color: #f6f8fb;
    flex-shrink: 0;
    display: flex;
    order: 2;
}

aside form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

aside button.button {
    background-color: #216bf6;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    border: 0;
    border-radius: 10px;
    padding: 0 15px;
    line-height: 43px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    transition: background-color 400ms ease, opacity 400ms ease;
}

aside button.button:disabled {
    opacity: 0.4;
}

aside button.button:hover {
    background-color: #0e4ec8;
}

aside button.button:before {
    content: "+";
    font-size: 16px;
    margin-right: 7px;
}

aside fieldset {
    border: 0;
    margin: 14px 14px 0 14px;
    padding: 0;
}

aside fieldset.submit {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    margin-bottom: 14px;
}

aside button.button.submit {
    background-color: #333;
}

aside button.button.submit:before {
    content: "→";
}

aside button.button.submit:hover {
    background-color: #222;
}

.mapMarker {
    margin: 15px 15px 0 15px;
    border: 2px solid #222;
    border-radius: 10px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
}

.mapMarker img {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 10px;
}

.mapMarker label {
    display: none;
}

.mapMarker fieldset {
    padding: 0;
    margin: 0;
    flex-grow: 1;
}

.mapMarker button {
    border: 0;
    background: transparent;
    font-size: 18px;
    cursor: pointer;
    color: #ccc;
}

.mapMarker input {
    font-size: 16px;
    width: 100%;
    background: transparent;
    border: 0;
    outline: none;
}

.marker-fake {
    position: absolute;
    z-index: 2000;
    left: 50%;
    top: calc(50% + 2px);
    transform: translate(-50%, -100%);
    opacity: 0.5;
    transition: opacity 200ms ease;
    pointer-events: none;
}

@media screen and (min-width: 800px) {
    .App {
        flex-direction: row;
    }

    aside {
        width: 300px;
        order: 1;
    }

    main {
        order: 2;
    }

    aside button.button {
        font-size: 16px;
        line-height: 50px;
    }

    aside button.button:before {
        font-size: 20px;
        margin-right: 10px;
    }

    .mapMarker {
        padding: 10px;
    }

    .mapMarker input {
        font-size: 16px;
    }

    .mapMarker img {
        width: 20px;
        height: 20px;
    }
}