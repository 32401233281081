.map-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.marker {
    background-image: url('../../assets/intoto-arrow.svg');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.popup-container {
    font-size: 14px;
    padding: 12px;
}

.popup-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 6px;
}

.popup-gps {
    white-space: nowrap;
}

.mapboxgl-popup-close-button {
    outline: none;
}